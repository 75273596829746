"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
interface PostHogPageViewProps {
  user: {
    id: string;
    email: string;
    firstname: string;
    lastname: string;
  } | null | undefined;
  organization: {
    id: string;
    subdomain: string;
    name: string;
  } | null | undefined;
}
export default function PostHogPageView({
  user,
  organization
}: PostHogPageViewProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();

  // Track pageviews
  useEffect(() => {
    if (posthog && user) {
      posthog.identify(user.id, {
        id: user.id,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        organizationId: organization?.id,
        organizationSubdomain: organization?.subdomain,
        organizationName: organization?.name
      });
    }
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }
      posthog.capture("$pageview", {
        $current_url: url
      });
    }
  }, [pathname, searchParams, posthog, user, organization]);
  return null;
}