"use client";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { env } from "~/env";
if (typeof window !== "undefined") {
  if (!env.NEXT_PUBLIC_POSTHOG_KEY) {
    console.warn("PostHog API Key not set. Analytics disabled.");
  } else {
    posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: "/ingest",
      ui_host: "https://eu.posthog.com",
      person_profiles: "always"
    });
  }
}
export function PHProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="PHProvider" data-sentry-source-file="posthog-provider.tsx">{children}</PostHogProvider>;
}